.input {
  border: none;
  height: 40px;
  width: 100%;
  background-color: transparent;
  outline: none;
  padding-left: 1em;
  &:focus {
    border: none;
    outline: none;
  }
  &:hover {
    border: none;
    outline: none;
    box-shadow: none;
  }
}
