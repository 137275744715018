body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#style-4::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #c5c5c5;
}
.ace_highlight_marker {
  position: absolute;
  background-color: #ffff00;
}

.MuiTooltip-tooltip {
  color: #fff;
  padding: 8px !important;
  font-size: 12px !important;
}
